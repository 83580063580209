import React from "react";
import ReactDOM from "react-dom";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./base.css";
import Demo from "./demo";

function App() {
  return (
    <Router basename="/">
      <div className="content">
        <h2 className="content__title">Soon</h2>
      </div>
      <div id="animation">
        <Switch>
          <Route exact path="/">
            <Demo />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
